<template>
  <div class="px-3 pb-5">
    <PageHeader title="동산 상환 관리" />

    <v-row no-gutters>
      <v-col cols="4" class="mt-2 mb-5 pa-2">
        <DongsanFunding :selectDongsan.sync="selectedDongsan" />
      </v-col>

      <v-col cols="8" class="my-2 pa-2">
        <keep-alive>
          <RepaymentPrincipal
            :key="selectedDongsan ? selectedDongsan.parent : null"
            :dongsan="selectedDongsan"
          />
        </keep-alive>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {
    DongsanFunding: () =>
      import('@/components/funda/funding/dongsan/DongsanFunding.vue'),
    RepaymentPrincipal: () =>
      import('@/components/funda/funding/dongsan/RepaymentPrincipal.vue'),
  },
  data() {
    return {
      selectedDongsan: null,
    }
  },
}
</script>
